import { useIsLoggedInWithLoading } from '@dreamstack/auth'
import {
  PropertySearchContextProvider,
  SearchProfileForm,
  SimpleMuiButton,
} from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'
import { Minus, Plus } from '@dreamstack/icons'
import type { FunctionComponent} from 'react';
import { useRef, useState } from 'react'
import 'twin.macro'

export const SearchProfileFormBlock: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const { isLoggedIn } = useIsLoggedInWithLoading()
  const [showSearchProfileForm, setShowSearchProfileForm] = useState(false)
  const button = useRef<HTMLButtonElement>(null)
  const t = useTranslation()

  return (
    <>
      <div tw="pb-px-24">
        <SimpleMuiButton
          ref={button}
          onClick={() => setShowSearchProfileForm(!showSearchProfileForm)}
          variant="text"
          color="primary"
          startIcon={!showSearchProfileForm ? <Plus /> : <Minus />}
        >
          {t('accentro:my.addSearchProfile')}
        </SimpleMuiButton>
      </div>
      {showSearchProfileForm && (
        <PropertySearchContextProvider>
          <SearchProfileForm
            addForAnonymousUser={!isLoggedIn}
            onSaveSearchCallback={() => {
              setShowSearchProfileForm(false)
              button.current?.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
              })
            }}
          />
        </PropertySearchContextProvider>
      )}
    </>
  )
}
