import { useHandleAuthFlowForAction } from '@dreamstack/auth'
import {
  BodyText,
  FormCheckbox,
  HeadingH3,
  LegalImprintCheckbox,
  NewsletterType,
  PropertySearchContext,
  SimpleMuiButton,
  useGetPropstackSavedQueryFromFilter,
  useNotificationContext,
  useYupValidationResolver,
  VeryLightGrayBox,
} from '@dreamstack/feature-components'
import { useLocale, useTranslation } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import { Trans } from 'next-i18next'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import tw from 'twin.macro'
import * as yup from 'yup'
import { InputForm } from '../../comingSoon/preferences/InputForm'

type SearchProfileFormProps = {
  cancelOnClick?: () => void
  addForAnonymousUser?: boolean
  onSaveSearchCallback?: () => void
}

const GrayHorizontalLine = tw.div`w-full border-t-2 border-accentroGray-200 mt-px-4 mb-px-16`
const SemiBold = tw.span`font-semibold`

export const SearchProfileForm: FunctionComponent<
  React.PropsWithChildren<SearchProfileFormProps>
> = ({
  cancelOnClick,
  addForAnonymousUser,
  onSaveSearchCallback,
}: SearchProfileFormProps) => {
  const utils = trpc.useContext()
  const t = useTranslation()
  const { addNotification } = useNotificationContext()

  const { mutateAsync: upsertQueryMutation, isLoading: saveQueryLoading } =
    trpc.savedQueries.upsertSavedQuery.useMutation()
  const {
    mutateAsync: sendMailSaveQuery,
    isLoading: sendMailSaveQueryIsLoading,
  } = trpc.magicAction.saveQuerySendMail.useMutation()
  const { mutateAsync: sendNewsletterRegistration } =
    trpc.newsletter.sendRegisterNewsletter.useMutation()

  const handleAuth = useHandleAuthFlowForAction()
  const transformFilterToPropstackSavedQuery =
    useGetPropstackSavedQueryFromFilter()
  const {
    propertySearchFilterValues,
    setPropertySearchFilterValues,
    betterDefaultFilterValues,
  } = useContext(PropertySearchContext)
  const yupValidationResolver = useEmailYupValidationForSearchProfileForm({
    addForAnonymousUser,
  })
  const methods = useForm<{ email: string; subscribeNewsletter: boolean }>({
    resolver: yupValidationResolver,
  })
  const { handleSubmit, reset } = methods

  const locale = useLocale()

  const onSaveSearch = () => {
    const actionCallback = async () => {
      const queryVars = transformFilterToPropstackSavedQuery({
        filter: propertySearchFilterValues,
      })
      if (!addForAnonymousUser) {
        await upsertQueryMutation({
          query: queryVars,
        })
        utils.savedQueries.getMySavedQueries.invalidate()
        addNotification('success', t('accentro:notifications.saveQuerySuccess'))
      } else {
        const { email, subscribeNewsletter } = methods.getValues()
        if (subscribeNewsletter && email) {
          sendNewsletterRegistration({
            email,
            type: NewsletterType.Newsletter,
            locale,
            wantsNewsletter: true,
          })
        }
        await sendMailSaveQuery({
          callbackUrl: '/my',
          email,
          locale,
          searchQuery: propertySearchFilterValues,
        })
        addNotification(
          'success',
          t('accentro:notifications.saveQuerySuccessMagicAction')
        )
      }
      onSaveSearchCallback && onSaveSearchCallback()

      // Reset property filter values and hook form values.
      setPropertySearchFilterValues(betterDefaultFilterValues)
      reset()
    }
    if (!addForAnonymousUser) {
      // TODO: Think about removing handleAuth in general here, since we have a way to handle the case for not logged in users now via magic actions.
      handleAuth({
        actionCallback,
      })
    } else {
      actionCallback()
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSaveSearch)}>
        <VeryLightGrayBox>
          <HeadingH3>
            <Trans
              i18nKey="accentro:dashboards.searchProfile.heading"
              components={{ semibold: <SemiBold /> }}
            ></Trans>
          </HeadingH3>
          <BodyText>
            <Trans
              i18nKey="accentro:dashboards.seachProfile.bodyText"
              components={{ semibold: <SemiBold /> }}
            ></Trans>
          </BodyText>

          <InputForm show={true} addForAnonymousUser={addForAnonymousUser} />
          {addForAnonymousUser && (
            <div>
              <GrayHorizontalLine />
              <div tw="col-span-2">
                <LegalImprintCheckbox name="consent" />
              </div>
              <div tw="col-span-full">
                <FormCheckbox
                  propertyName="subscribeNewsletter"
                  labelComponent={`${t(
                    'accentro:contactform.consentNewsletterText'
                  )}`}
                />
              </div>
            </div>
          )}
          <div tw="flex flex-col gap-y-px-16 sm:(flex-row gap-x-px-24 gap-y-0) col-start-1 ">
            <SimpleMuiButton
              variant="contained"
              type="submit"
              disabled={false}
              color="primary"
              loading={saveQueryLoading || sendMailSaveQueryIsLoading}
              loadingText={t('accentro:dashboards.searchProfile.loadingText')}
            >
              {t('accentro:dashboards.searchProfile.buttonText')}
            </SimpleMuiButton>
            <div tw="flex">
              <SimpleMuiButton
                variant="text"
                color="primary"
                onClick={cancelOnClick}
              >
                {t('accentro:cancel')}
              </SimpleMuiButton>
            </div>
          </div>
        </VeryLightGrayBox>
      </form>
    </FormProvider>
  )
}

const useEmailYupValidationForSearchProfileForm = ({
  addForAnonymousUser,
}: {
  addForAnonymousUser?: boolean
}) => {
  const t = useTranslation()
  const schemaWithRequiredFields = useYupValidationResolver(
    yup.object({
      email: yup
        .string()
        .email(t('accentro:my.myProfile.userDataSection.emailadressValidation'))
        .required(t('accentro:requiredField')),
      consent: yup.boolean().oneOf([true], t('accentro:requiredField')),
    })
  )
  const schemaWithoutRequiredEmail = useYupValidationResolver(
    yup.object({
      email: yup
        .string()
        .email(
          t('accentro:my.myProfile.userDataSection.emailadressValidation')
        ),
    })
  )

  if (!addForAnonymousUser) {
    return schemaWithoutRequiredEmail
  } else {
    return schemaWithRequiredFields
  }
}
