import type {
  ProjectOverviewListProps,
  QueryAllBlocksRecursivelyResult,
  RegionAverageTileExistingCustomProps,
} from '@dreamstack/accentro-contentful'
import type { CustomBlockFragment } from '@dreamstack/investors-graphql'
import dynamic from 'next/dynamic'
import type { ComponentType, FunctionComponent } from 'react'
import type {
  FeaturedArticleCustomProps,
  FeaturedArticleProps,
} from './FeaturedArticles'
import type {
  NewsArticleListCustomProps,
  NewsArticleListProps,
} from './NewsArticleList'
import type { RegionAverageTileNewCustomProps } from './RegionAverageTileNew'
import type { RegionAverageTilesCustomProps } from './RegionAverageTiles'
import type { RegionAvgBuyPricesCandleStickCustomProps } from './RegionGraphs/RegionAvgBuyPricesCandleStick'
import type {
  RegionAvgBuyPricesScatterPlotCustomProps,
  RegionAvgBuyPricesScatterPlotProps,
} from './RegionGraphs/RegionAvgBuyPricesScatterPlot'
import type {
  RegionAvgRentPricesCandleStickCustomProps,
  RegionAvgRentPricesCandleStickProps,
} from './RegionGraphs/RegionAvgRentPricesCandleStick'
import type {
  RegionAvgRentPricesScatterAndCandlestickCustomProps,
  RegionAvgRentPricesScatterAndCandlestickProps,
} from './RegionGraphs/RegionAvgRentPricesScatterAndCandlestick'
import type {
  RegionBarGraphCustomProps,
  RegionBarGraphProps,
} from './RegionGraphs/RegionBarGraph'
import type {
  RegionPieChartCustomProps,
  RegionPieChartProps,
} from './RegionGraphs/RegionPieChart'
import type {
  RegionGeoMapCustomProps,
  RegionGeoMapProps,
} from './RegionGraphs/libs/typings'
import { SearchProfileFormBlock } from './SearchProfileFormBlock'

const AllPropertyGermanyMap: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./AllPropertyGermanyMap').then((m) => m.AllPropertyGermanyMap)
  )
const HomeOwnershipReportOrderForm: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./HomeOwnershipReportOrderForm').then(
      (mod) => mod.HomeOwnershipReportOrderForm
    )
  )
const HousingCostReportOrderForm: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./HousingCostReportOrderForm').then(
      (mod) => mod.HousingCostReportOrderForm
    )
  )
const SimpleError: ComponentType<
  React.PropsWithChildren<{ msg: string; json?: any }>
> = dynamic(() =>
  import('@dreamstack/feature-components').then((mod) => mod.SimpleError)
)
const FeaturedArticles: ComponentType<
  React.PropsWithChildren<FeaturedArticleProps>
> = dynamic(() =>
  import('./FeaturedArticles').then((mod) => mod.FeaturedArticles)
)
const FindYourProperties: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./FindYourProperties').then((mod) => mod.FindYourProperties)
)
const HomeOwnershipReport: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () =>
    import('./HomeOwnershipReport/HomeOwnershipReport').then(
      (mod) => mod.HomeOwnershipReport
    )
)
const HypoFriendWidget: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./HypoFriendWidget').then((mod) => mod.HypoFriendWidget)
)
const IS24AdvantagesStrengths: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./IS24AdvantagesStrengths').then(
      (mod) => mod.IS24AdvantagesStrengths
    )
  )
const IS24ContactFormBlock: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./IS24ContactFormBlock').then((mod) => mod.IS24ContactFormBlock)
  )
const LandingStockPriceSection: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./LandingStockPriceSection').then(
      (mod) => mod.LandingStockPriceSection
    )
  )
const LtvCalculator: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./LtvCalculator').then((mod) => mod.LtvCalculator)
)
const NewsArticleList: ComponentType<
  React.PropsWithChildren<NewsArticleListProps>
> = dynamic(() =>
  import('./NewsArticleList').then((mod) => mod.NewsArticleList)
)
const NewsletterCustom: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./NewsletterCustom').then((mod) => mod.NewsletterCustom)
)
const ProjectOverviewList: ComponentType<
  React.PropsWithChildren<ProjectOverviewListProps>
> = dynamic(() =>
  import('./ProjectOverviewList').then((mod) => mod.ProjectOverviewList)
)
const RegionAverageTileExisting: ComponentType<
  React.PropsWithChildren<{
    customProps: RegionAverageTileExistingCustomProps
  }>
> = dynamic(() =>
  import('./RegionAverageTileExisting').then(
    (mod) => mod.RegionAverageTileExisting
  )
)
const RegionAverageTileNew: ComponentType<
  React.PropsWithChildren<{
    customProps: RegionAverageTileNewCustomProps
  }>
> = dynamic(() =>
  import('./RegionAverageTileNew').then((mod) => mod.RegionAverageTileNew)
)
const RegionAverageTiles: ComponentType<
  React.PropsWithChildren<{
    customProps: RegionAverageTilesCustomProps
  }>
> = dynamic(() =>
  import('./RegionAverageTiles').then((mod) => mod.RegionAverageTiles)
)
const RegionAvgBuyPricesCandleStick: ComponentType<
  React.PropsWithChildren<{
    customProps: RegionAverageTilesCustomProps
  }>
> = dynamic(() =>
  import('./RegionGraphs/RegionAvgBuyPricesCandleStick').then(
    (mod) => mod.RegionAvgBuyPricesCandleStick
  )
)
const RegionAvgBuyPricesScatterPlot: ComponentType<
  React.PropsWithChildren<RegionAvgBuyPricesScatterPlotProps>
> = dynamic(() =>
  import('./RegionGraphs/RegionAvgBuyPricesScatterPlot').then(
    (mod) => mod.RegionAvgBuyPricesScatterPlot
  )
)
const RegionAvgRentPricesCandleStick: ComponentType<
  React.PropsWithChildren<RegionAvgRentPricesCandleStickProps>
> = dynamic(() =>
  import('./RegionGraphs/RegionAvgRentPricesCandleStick').then(
    (mod) => mod.RegionAvgRentPricesCandleStick
  )
)
const RegionAvgRentPricesScatterAndCandlestick: ComponentType<
  React.PropsWithChildren<RegionAvgRentPricesScatterAndCandlestickProps>
> = dynamic(() =>
  import('./RegionGraphs/RegionAvgRentPricesScatterAndCandlestick').then(
    (mod) => mod.RegionAvgRentPricesScatterAndCandlestick
  )
)
const RegionBarGraph: ComponentType<
  React.PropsWithChildren<RegionBarGraphProps>
> = dynamic(() =>
  import('./RegionGraphs/RegionBarGraph').then((mod) => mod.RegionBarGraph)
)
const RegionGeoMap: ComponentType<React.PropsWithChildren<RegionGeoMapProps>> =
  dynamic(() =>
    import('./RegionGraphs/RegionGeoMap').then((mod) => mod.RegionGeoMap)
  )
const RegionPieChart: ComponentType<
  React.PropsWithChildren<RegionPieChartProps>
> = dynamic(() =>
  import('./RegionGraphs/RegionPieChart').then((mod) => mod.RegionPieChart)
)
const RegionQuickSelector: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./RegionQuickSelector').then((mod) => mod.RegionQuickSelector)
)
const RoadShowRegistration: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./RoadShowRegistration').then((mod) => mod.RoadShowRegistration)
  )
const SideBySidePropertyComparison: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./SideBySidePropertyComparison').then(
      (mod) => mod.SideBySidePropertyComparison
    )
  )
const WerGridTable: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./WerGridTable').then((mod) => mod.WerGridTable)
)
const WkrBarGraph: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./WohnKostenReport/WkrBarGraph').then((mod) => mod.WkrBarGraph)
)
const WkrGermanyMap: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./WohnKostenReport/WkrGermanyMap').then((mod) => mod.WkrGermanyMap)
)
const WkrLineGraph: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./WohnKostenReport/WkrLineGraph').then((mod) => mod.WkrLineGraph)
)
const Awards: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./Awards').then((mod) => mod.Awards)
)
const AppstoreButtons: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./AppstoreButtons').then((mod) => mod.AppstoreButtons)
)
const BasicMortgageTabs: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./BasicMortgageTabs').then((mod) => mod.BasicMortgageTabs)
)
const BerlinDistrictSelector: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./BerlinDistrictSelector').then((mod) => mod.BerlinDistrictSelector)
  )
const BerlinMapMilieuschutz: ComponentType<React.PropsWithChildren<{}>> =
  dynamic(() =>
    import('./BerlinMapMilieuschutz').then((mod) => mod.BerlinMapMilieuschutz)
  )
const BuyToLetCalculator: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./BuyToLetCalculator').then((mod) => mod.BuyToLetCalculator)
)
const CapitalInvestmentGuideOrderForm: ComponentType<
  React.PropsWithChildren<{}>
> = dynamic(() =>
  import('./CapitalInvestmentGuideOrderForm').then(
    (mod) => mod.CapitalInvestmentGuideOrderForm
  )
)
const CompanyFeatures: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./CompanyFeatures').then((mod) => mod.CompanyFeatures)
)
const CompanyStructure: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./CompanyStructure').then((mod) => mod.CompanyStructure)
)
const ContactFormBlock: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./ContactFormBlock').then((mod) => mod.ContactFormBlock)
)
const ProvidersAgents_ContactFormBlock: ComponentType<
  React.PropsWithChildren<{}>
> = dynamic(() =>
  import('./ProvidersAgents_ContactFormBlock').then(
    (mod) => mod.ProvidersAgents_ContactFormBlock
  )
)
const ContactUs: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./ContactUs').then((mod) => mod.ContactUs)
)
const CurrentPressArticles: ComponentType<
  React.PropsWithChildren<{
    block: QueryAllBlocksRecursivelyResult<CustomBlockFragment>
  }>
> = dynamic(() =>
  import('./CurrentPressArticles').then((mod) => mod.CurrentPressArticles)
)
const EquityStoryIframe: ComponentType<React.PropsWithChildren<{}>> = dynamic(
  () => import('./EquityStoryIframe').then((mod) => mod.EquityStoryIframe)
)

const TrustBox: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./TrustPilot/TrustBox').then((mod) => mod.TrustBox)
)

const TrustSlider: ComponentType<React.PropsWithChildren<{}>> = dynamic(() =>
  import('./TrustPilot/TrustSlider').then((mod) => mod.TrustSlider)
)

type CustomBlockType =
  | 'EquityStoryIframe'
  | 'LandingStockPriceSection'
  | 'CurrentPressArticles'
  | 'RegionQuickSelector'
  | 'ContactForm'
  | 'BasicMortgageTabs'
  | 'ContactUs'
  | 'HypoFriendWidget'
  | 'BerlinDistrictSelector'
  | 'AppstoreButtons'
  | 'ProjectOverviewList'
  | 'CompanyFeatures'
  | 'CompanyStructure'
  | 'HomeOwnershipReport'
  | 'HousingCostReport'
  | 'AllPropertyGermanyMap'
  | 'WerGridTable'
  | 'HomeOwnershipReportOrderForm'
  | 'HousingCostReportOrderForm'
  | 'CapitalInvestmentGuideOrderForm'
  | 'Newsletter'
  | 'SideBySidePropertyComparison'
  | 'LtvCalculator'
  | 'BuyToLetCalculator'
  | 'FindYourProperties'
  | 'FeaturedArticles'
  | 'NewsArticleList'
  | 'IS24_ContactForm'
  | 'IS24_Advantages_Strengths'
  | 'RoadShowRegistration'
  | 'Awards'
  | 'TrustBox'
  | 'TrustSlider'
  | 'RegionBarGraph'
  | 'WkrLineGraph'
  | 'WkrBarGraph'
  | 'WkrGermanyMap'
  | 'RegionPieChart'
  | 'RegionAverageTiles'
  | 'RegionBarGraph'
  | 'RegionAvgRentPricesCandleStick'
  | 'RegionAvgBuyPricesCandleStick'
  | 'RegionAvgBuyPricesScatterPlot'
  | 'RegionAvgRentPricesScatterAndCandlestick'
  | 'BerlinMapMilieuschutz'
  | 'RegionGeoMap'
  | 'RegionAverageTileNew'
  | 'RegionAverageTileExisting'
  | 'SearchProfileForm'
  | 'ProvidersAgents_ContactForm'

export const CustomBlock: FunctionComponent<
  React.PropsWithChildren<{
    block: QueryAllBlocksRecursivelyResult<CustomBlockFragment>
    customProps?:
      | FeaturedArticleCustomProps
      | RegionAvgRentPricesScatterAndCandlestickCustomProps
      | RegionAvgBuyPricesScatterPlotCustomProps
      | NewsArticleListCustomProps
      | RegionAvgBuyPricesCandleStickCustomProps
      | RegionAvgRentPricesCandleStickCustomProps
      | RegionGeoMapCustomProps
  }>
> = ({ block, customProps }) => {
  const type = block.customBlockType as CustomBlockType
  switch (type) {
    case 'EquityStoryIframe':
      return <EquityStoryIframe />
    case 'LandingStockPriceSection':
      return <LandingStockPriceSection />
    case 'CurrentPressArticles':
      return <CurrentPressArticles block={block} />
    case 'RegionQuickSelector':
      return <RegionQuickSelector />
    case 'BasicMortgageTabs':
      return <BasicMortgageTabs />
    case 'ContactForm':
      return <ContactFormBlock />
    case 'ProvidersAgents_ContactForm':
      return <ProvidersAgents_ContactFormBlock />
    case 'AppstoreButtons':
      return <AppstoreButtons />
    case 'ProjectOverviewList':
      return (
        <ProjectOverviewList
          projects={block.customBlockStuff?.projectOverviewList?.projects}
          comingSoonProjects={
            block.customBlockStuff?.projectOverviewList?.comingSoonProjects
          }
        />
      )
    case 'CompanyFeatures':
      return <CompanyFeatures />
    case 'CompanyStructure':
      return <CompanyStructure />
    case 'ContactUs':
      return <ContactUs />
    case 'HypoFriendWidget':
      return <HypoFriendWidget />
    case 'HomeOwnershipReport':
      return <HomeOwnershipReport />
    case 'AllPropertyGermanyMap':
      return <AllPropertyGermanyMap />
    case 'WerGridTable':
      return <WerGridTable />
    case 'HomeOwnershipReportOrderForm':
      return <HomeOwnershipReportOrderForm />
    case 'HousingCostReportOrderForm':
      return <HousingCostReportOrderForm />
    case 'CapitalInvestmentGuideOrderForm':
      return <CapitalInvestmentGuideOrderForm />
    case 'Newsletter':
      return <NewsletterCustom />
    case 'SideBySidePropertyComparison':
      return <SideBySidePropertyComparison />
    case 'LtvCalculator':
      return <LtvCalculator />
    case 'BuyToLetCalculator':
      return <BuyToLetCalculator />
    case 'BerlinDistrictSelector':
      return <BerlinDistrictSelector />
    case 'FindYourProperties':
      return <FindYourProperties />
    case 'FeaturedArticles':
      return <FeaturedArticles block={block} />
    case 'NewsArticleList':
      return (
        <NewsArticleList
          block={block}
          customProps={customProps as NewsArticleListCustomProps}
        />
      )
    case 'IS24_ContactForm':
      return <IS24ContactFormBlock />
    case 'IS24_Advantages_Strengths':
      return <IS24AdvantagesStrengths />
    case 'RoadShowRegistration':
      return <RoadShowRegistration />
    case 'Awards':
      return <Awards />
    case 'TrustBox':
      return <TrustBox />
    case 'TrustSlider':
      return <TrustSlider />
    case 'WkrLineGraph':
      return <WkrLineGraph />
    case 'WkrBarGraph':
      return <WkrBarGraph />
    case 'WkrGermanyMap':
      return <WkrGermanyMap />
    case 'RegionPieChart':
      return (
        <RegionPieChart
          customProps={customProps as RegionPieChartCustomProps}
        />
      )
    case 'RegionAverageTiles':
      return (
        <RegionAverageTiles
          customProps={customProps as RegionAverageTilesCustomProps}
        />
      )
    case 'RegionBarGraph':
      return (
        <RegionBarGraph
          customProps={customProps as RegionBarGraphCustomProps}
        />
      )
    case 'RegionAvgRentPricesCandleStick':
      return (
        <RegionAvgRentPricesCandleStick
          customProps={customProps as RegionAvgRentPricesCandleStickCustomProps}
        />
      )
    case 'RegionAvgBuyPricesCandleStick':
      return (
        <RegionAvgBuyPricesCandleStick
          customProps={customProps as RegionAvgBuyPricesCandleStickCustomProps}
        />
      )
    case 'RegionAvgBuyPricesScatterPlot':
      return (
        <RegionAvgBuyPricesScatterPlot
          customProps={customProps as RegionAvgBuyPricesScatterPlotCustomProps}
        />
      )
    case 'RegionAvgRentPricesScatterAndCandlestick':
      return (
        <RegionAvgRentPricesScatterAndCandlestick
          customProps={
            customProps as RegionAvgRentPricesScatterAndCandlestickCustomProps
          }
        />
      )
    case 'RegionGeoMap':
      return (
        <RegionGeoMap customProps={customProps as RegionGeoMapCustomProps} />
      )
    case 'BerlinMapMilieuschutz':
      return <BerlinMapMilieuschutz />
    case 'RegionAverageTileNew':
      return (
        <RegionAverageTileNew
          customProps={customProps as RegionAverageTileNewCustomProps}
        />
      )
    case 'RegionAverageTileExisting':
      return (
        <RegionAverageTileExisting
          customProps={customProps as RegionAverageTileExistingCustomProps}
        />
      )
    case 'SearchProfileForm':
      return <SearchProfileFormBlock />
    default:
      return <SimpleError msg={`Unknown CustomBlockType "${type}"`} />
  }
}
